<template>
  <div
    v-if="$store.state.fastPermissoes.administrador == 'S' && $store.state.fastPermissoes.ativo == 'S'"
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >{{ $store.state.fastPersonalizacao.minhas_capacitacoes_nome ? $store.state.fastPersonalizacao.minhas_capacitacoes_nome : 'Matriz de capacitação' }}</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 v-if="!$store.state.fastPersonalizacao.minhas_capacitacoes_nome">
                  Matriz de
                  <span id="txtDashboardNomePlataforma">capacitação</span>
                </h1>
                <h1 v-else>
                  {{ $store.state.fastPersonalizacao.minhas_capacitacoes_nome }}
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1">
                  <h4>
                    Lista de
                    <span
                      v-if="!$store.state.fastPersonalizacao.minhas_capacitacoes_setor"
                    >setores</span>
                    <span v-else>{{ $store.state.fastPersonalizacao.minhas_capacitacoes_setor }}</span>
                  </h4>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-8 mt-4 mb-1 text-right">
                  <button
                    class="btn btn-primary"
                    @click.prevent="showModal('modalCriarSetor')"
                  >
                    <small
                      v-if="!$store.state.fastPersonalizacao.minhas_capacitacoes_setor"
                    >+ Adicionar Setor</small>
                    <small
                      v-else
                    >+ Adicionar {{ $store.state.fastPersonalizacao.minhas_capacitacoes_setor }}</small>
                  </button>
                </div>

                <div
                  v-if="fastDepartamento.length"
                  class="col-12 mt-2"
                >
                  <div
                    v-for="d in fastDepartamento"
                    :key="d.id_departamento"
                    class="row"
                  >
                    <div class="col-12">
                      <h6>{{ d.sigla }} - {{ d.nome_departamento }}</h6>
                      <tabs :options="{ useUrlFragment: false }">
                        <tab
                          prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                          :name="$store.state.fastPersonalizacao.minhas_capacitacoes_cargo ? $store.state.fastPersonalizacao.minhas_capacitacoes_cargo : 'Cargos'"
                        >
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <h6
                                v-if="!$store.state.fastPersonalizacao.minhas_capacitacoes_cargo"
                              >
                                Lista de cargos
                              </h6>
                              <h6
                                v-else
                              >
                                {{ $store.state.fastPersonalizacao.minhas_capacitacoes_cargo }}
                              </h6>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                              <button
                                class="btn btn-sm btn-primary"
                                @click.prevent="exibeModalCriarCargo(d.id_departamento)"
                              >
                                <small
                                  v-if="!$store.state.fastPersonalizacao.minhas_capacitacoes_cargo"
                                >+ Adicionar Cargo</small>
                                <small>+ Adicionar {{ $store.state.fastPersonalizacao.minhas_capacitacoes_cargo }}</small>
                              </button>
                            </div>
                            <div class="col-12 table-responsive mt-2">
                              <table
                                v-if="d.cargos"
                                class="table table-sm table-striped"
                              >
                                <thead class="thead-dark">
                                  <tr>
                                    <th class="text-center">
                                      <small
                                        v-if="!$store.state.fastPersonalizacao.minhas_capacitacoes_cargo"
                                        class="font-weight-bold"
                                      >Sigla do cargo</small>
                                      <small
                                        v-else
                                        class="font-weight-bold"
                                      >Sigla</small>
                                    </th>
                                    <th>
                                      <small
                                        v-if="!$store.state.fastPersonalizacao.minhas_capacitacoes_cargo"
                                        class="font-weight-bold"
                                      >Nome do cargo</small>
                                      <small
                                        v-else
                                        class="font-weight-bold"
                                      >Nome</small>
                                    </th>
                                    <th class="text-center">
                                      <small
                                        v-if="!$store.state.fastPersonalizacao.minhas_capacitacoes_nome"
                                        class="font-weight-bold"
                                      >Matriz de capacitação</small>
                                      <small
                                        v-else
                                        class="font-weight-bold"
                                      >{{ $store.state.fastPersonalizacao.minhas_capacitacoes_nome }}</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Ações</small>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody v-if="d.cargos.length">
                                  <tr
                                    v-for="cargo in d.cargos"
                                    :key="cargo.id_cargo"
                                  >
                                    <td class="text-center align-middle">
                                      <small>{{ cargo.sigla_Cargo }}</small>
                                    </td>
                                    <td class="align-middle">
                                      <small>{{ cargo.nome_cargo }}</small>
                                    </td>
                                    <td class="text-center align-middle">
                                      <div v-if="cargo.cursos">
                                        <ul
                                          v-if="cargo.cursos.length"
                                          class="list-group"
                                        >
                                          <li
                                            v-for="curso in cargo.cursos"
                                            :key="curso.id_curso"
                                            class="list-group-item d-flex justify-content-between align-items-center pt-1 pb-1"
                                          >
                                            {{ curso.nome_curso }}
                                            <a
                                              href="#"
                                              class="badge badge-danger badge-pill"
                                              @click="removeMatriz(d.id_departamento, cargo.id_cargo, curso.id_curso)"
                                            >Remover</a>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                    <td class="text-center align-middle">
                                      <button
                                        class="btn btn-sm btn-success mr-2"
                                        @click="exibeModalCursosDisponiveis(cargo)"
                                      >
                                        <small>Cursos</small>
                                      </button>
                                      <button
                                        class="btn btn-sm btn-primary mr-2"
                                        @click="exibeModalEditarCargo(cargo)"
                                      >
                                        <small>Editar</small>
                                      </button>
                                      <button
                                        class="btn btn-sm btn-danger"
                                        @click="exibeModalExcluirCargo(cargo)"
                                      >
                                        <small>Excluir</small>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </tab>
                        <tab
                          prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                          name="Opções"
                        >
                          <button
                            class="btn btn-sm btn-primary mr-2"
                            @click="exibeModalEditarDepartamento(d)"
                          >
                            <small>Editar</small>
                          </button>
                          <button
                            class="btn btn-sm btn-danger"
                            @click="exibeModalExcluirDepartamento(d)"
                          >
                            <small>Excluir</small>
                          </button>
                        </tab>
                      </tabs>
                    </div>
                  </div>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarSetor"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4 v-if="!$store.state.fastPersonalizacao.minhas_capacitacoes_setor">
            Novo setor
          </h4>
          <h4 v-else>
            {{ $store.state.fastPersonalizacao.minhas_capacitacoes_setor }}
          </h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarSetor')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <label for>Sigla</label>
              <input
                v-model="fastDepartamentoNovo.sigla"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-12 mt-4">
              <label>Nome</label>
              <input
                v-model="fastDepartamentoNovo.nome_departamento"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarDepartamento()"
                >
                  Criar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarDepartamento"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4 v-if="!$store.state.fastPersonalizacao.minhas_capacitacoes_setor">
            Editar setor
          </h4>
          <h4 v-else>
            {{ $store.state.fastPersonalizacao.minhas_capacitacoes_setor }}
          </h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarDepartamento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <label for>Sigla</label>
              <input
                v-model="fastDepartamentoEditar.sigla"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-12">
              <label for>Nome</label>
              <input
                v-model="fastDepartamentoEditar.nome_departamento"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarDepartamento()"
                >
                  Atualizar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirDepartamento"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4 v-if="!$store.state.fastPersonalizacao.minhas_capacitacoes_setor">
            Excluir setor?
          </h4>
          <h4 v-else>
            {{ $store.state.fastPersonalizacao.minhas_capacitacoes_setor }}
          </h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirDepartamento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click="excluirDepartamento()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalCriarCargo"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4 v-if="!$store.state.fastPersonalizacao.minhas_capacitacoes_cargo">
            Novo cargo
          </h4>
          <h4 v-else>
            Criar {{ $store.state.fastPersonalizacao.minhas_capacitacoes_cargo }}
          </h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarCargo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <label for>Sigla</label>
              <input
                v-model="fastCargoNovo.sigla_Cargo"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-12">
              <label for>Nome</label>
              <input
                v-model="fastCargoNovo.nome_cargo"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarCargo()"
                >
                  Criar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarCargo"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4 v-if="!$store.state.fastPersonalizacao.minhas_capacitacoes_cargo">
            Editar cargo
          </h4>
          <h4 v-else>
            Editar {{ $store.state.fastPersonalizacao.minhas_capacitacoes_cargo }}
          </h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarCargo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <label for>Sigla</label>
              <input
                v-model="fastCargoEditar.sigla_Cargo"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-12">
              <label for>Nome</label>
              <input
                v-model="fastCargoEditar.nome_cargo"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarCargo()"
                >
                  Atualizar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirCargo"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Tem certeza que deseja excluir?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirCargo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click="excluirCargo()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalCursosDisponiveis"
      :scrollable="true"
      :max-width="992"
      width="100%"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Cursos disponíveis ({{ cursosFiltro.length }})</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCursosDisponiveis')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 pb-2">
              <input
                type="text"
                class="form-control"
                placeholder="Filtrar por nome"
                @keyup="filtraCurso"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th class="text-center">
                      Id
                    </th>
                    <th scope="col">
                      Nome do curso
                    </th>
                    <th class="text-center">
                      Carga horária
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                    >
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody v-if="pageOfCursos.length">
                  <tr
                    v-for="c in pageOfCursos"
                    :key="c.id_curso"
                  >
                    <td class="align-middle text-center">
                      {{ c.id_curso }}
                    </td>
                    <td class="align-middle">
                      {{ c.nome_curso }}
                    </td>
                    <td class="align-middle text-center">
                      {{ c.carga_horaria }}h
                    </td>
                    <td class="align-middle text-center">
                      <button
                        class="btn btn-sm btn-success btn-block"
                        title="Adicionar este curso"
                        @click="criaMatriz(c.id_curso)"
                      >
                        <small>
                          <b-icon-person-plus-fill /> Curso
                        </small>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="4"
                    >
                      Nenhum curso disponível
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="5"
                :items="cursosFiltro"
                @changePage="pageOfCursos = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";

export default {
  name: "HomeInternoAdminMatrizCapacitacao",
  components: {
    Pagination,
  },
  mixins: [methods],
  data: function () {
    return {
      //ImageSiteFormEffect01,
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      // Departamentos
      fastDepartamento: [],
      fastDepartamentoNovo: {
        id_plataforma: 0,
        sigla: "",
        nome_departamento: "",
      },
      fastDepartamentoEditar: {
        id_plataforma: 0,
        sigla: "",
        nome_departamento: "",
      },
      fastDepartamentoExcluir: {
        id_plataforma: 0,
        sigla: "",
        nome_departamento: "",
      },
      // Cargo
      fastCargoNovo: {
        id_cargo: 0,
        sigla_Cargo: "",
        nome_cargo: "",
        id_departamento: 0,
      },
      fastCargoEditar: {
        id_cargo: "",
        sigla_Cargo: "",
        nome_cargo: "",
        id_departamento: "",
      },
      fastCargoExcluir: {
        id_cargo: "",
        sigla_Cargo: "",
        nome_cargo: "",
        id_departamento: "",
      },
      // Cursos disponíveis
      cursosTotal: [],
      cursosFiltro: [],
      pageOfCursos: [],
      // Matriz
      fastMatrizNova: {
        id_plataforma: 0,
        id_departamento: "",
        id_cargo: "",
        id_curso: 0,
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {
          this.getFastDepartamentos();
          this.getCursosDisponiveis();
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    // Departamentos
    async getFastDepartamentos() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_departamento/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          obj.forEach((e) => {
            e.cargos = [];
            this.getFastCargos(e.id_departamento);
          });
          this.fastDepartamento = obj;
        } else {
          this.fastDepartamento = [];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async criarDepartamento() {
      let erros = [];
      if (!this.fastDepartamentoNovo.sigla) erros.push("A sigla é obrigatória");
      if (this.fastDepartamentoNovo.sigla.length > 50)
        erros.push("A sigla deve possuir no máximo 50 caracteres");
      if (!this.fastDepartamentoNovo.nome_departamento)
        erros.push("O nome do setor é obrigatório");
      if (this.fastDepartamentoNovo.nome_departamento.length > 150)
        erros.push("O nome deve possuir no máximo 150 caracteres");

      if (erros.length) {
        erros.forEach((e) => {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Erro",
            text: e,
          });
        });
      } else {
        this.fastDepartamentoNovo.id_plataforma = this.$route.params.id_plataforma;
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_plataforma_departamento/insere",
            this.fastAjaxOptions(
              "POST",
              JSON.stringify(this.fastDepartamentoNovo)
            )
          );

          let json = await resp.json();
          let obj = Array.from(json);
          this.getFastDepartamentos();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Criado com sucesso",
          });
          this.hideModal("modalCriarSetor");
        } catch (e) {
          console.log("Erro", e);
        }
      }
    },
    exibeModalEditarDepartamento(d) {
      this.fastDepartamentoEditar = d;
      this.showModal("modalEditarDepartamento");
    },
    async editarDepartamento() {
      let erros = [];
      if (!this.fastDepartamentoEditar.sigla)
        erros.push("A sigla é obrigatória");
      if (this.fastDepartamentoEditar.sigla.length > 100)
        erros.push("A sigla deve possuir no máximo 100 caracteres");
      if (!this.fastDepartamentoEditar.nome_departamento)
        erros.push("O nome do setor é obrigatório");
      if (this.fastDepartamentoEditar.nome_departamento.length > 200)
        erros.push("O nome deve possuir no máximo 200 caracteres");
      if (erros.length) {
        erros.forEach((e) => {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Erro",
            text: e,
          });
        });
      } else {
        this.fastDepartamentoNovo.id_plataforma = this.$route.params.id_plataforma;
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_plataforma_departamento/atualiza",
            this.fastAjaxOptions(
              "POST",
              JSON.stringify(this.fastDepartamentoEditar)
            )
          );

          let json = await resp.json();
          let obj = Array.from(json);
          this.getFastDepartamentos();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Setor alterado com sucesso",
          });
          this.hideModal("modalEditarDepartamento");
        } catch (e) {
          console.log("Erro", e);
        }
      }
    },
    exibeModalExcluirDepartamento(d) {
      this.fastDepartamentoExcluir = d;
      this.showModal("modalExcluirDepartamento");
    },
    async excluirDepartamento() {
      this.fastDepartamentoExcluir.id_plataforma = this.$route.params.id_plataforma;
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma_departamento/exclui",
          this.fastAjaxOptions(
            "POST",
            JSON.stringify(this.fastDepartamentoExcluir)
          )
        );

        let json = await resp.json();
        let obj = Array.from(json);
        this.getFastDepartamentos();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Setor excluído com sucesso",
        });
        this.hideModal("modalExcluirDepartamento");
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Cargos
    async getFastCargos(id_departamento) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_cargo/lista?id_departamento=" +
            id_departamento,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          obj.forEach((c) => (c.cursos = []));
          this.fastDepartamento.forEach((e, index) => {
            if (e.id_departamento == id_departamento) {
              this.fastDepartamento[index].cargos = obj;
            }
          });
        } else {
          this.fastDepartamento.forEach((e, index) => {
            if (e.id_departamento == id_departamento) {
              this.fastDepartamento[index].cargos = [];
            }
          });
        }
        obj.forEach((c) => this.getCursosCargo(c.id_cargo));
        //console.log(this.fastDepartamento);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalCriarCargo(id_departamento) {
      this.fastCargoNovo.id_departamento = id_departamento;
      this.showModal("modalCriarCargo");
    },
    async criarCargo() {
      let erros = [];
      if (!this.fastCargoNovo.sigla_Cargo) erros.push("A sigla é obrigatória");
      if (this.fastCargoNovo.sigla_Cargo.length > 100)
        erros.push("A sigla deve possuir no máximo 100 caracteres");
      if (!this.fastCargoNovo.nome_cargo)
        erros.push("O nome do cargo é obrigatório");
      if (this.fastCargoNovo.nome_cargo.length > 200)
        erros.push("O nome deve possuir no máximo 200 caracteres");
      if (erros.length) {
        erros.forEach((e) => {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Erro",
            text: e,
          });
        });
      } else {
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_plataforma_cargo/insere",
            this.fastAjaxOptions("POST", JSON.stringify(this.fastCargoNovo))
          );

          let json = await resp.json();
          let obj = Array.from(json);
          this.getFastDepartamentos();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Cargo criado com sucesso",
          });
          this.hideModal("modalCriarCargo");
        } catch (e) {
          console.log("Erro", e);
        }
      }
    },
    exibeModalEditarCargo(cargo) {
      this.fastCargoEditar = cargo;
      this.showModal("modalEditarCargo");
    },
    async editarCargo() {
      let erros = [];
      if (!this.fastCargoEditar.sigla_Cargo)
        erros.push("A sigla é obrigatória");
      if (!this.fastCargoEditar.nome_cargo)
        erros.push("O nome do cargo é obrigatório");

      if (erros.length) {
        erros.forEach((e) => {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Erro",
            text: e,
          });
        });
      } else {
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_plataforma_cargo/atualiza",
            this.fastAjaxOptions("POST", JSON.stringify(this.fastCargoEditar))
          );

          let json = await resp.json();
          let obj = Array.from(json);
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Cargo alterado com sucesso",
          });
          this.hideModal("modalEditarCargo");
        } catch (e) {
          console.log("Erro", e);
        }
      }
    },
    exibeModalExcluirCargo(cargo) {
      this.fastCargoExcluir = cargo;
      this.showModal("modalExcluirCargo");
    },
    async excluirCargo() {
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma_cargo/exclui",
          this.fastAjaxOptions("POST", JSON.stringify(this.fastCargoExcluir))
        );

        let json = await resp.json();
        let obj = Array.from(json);
        this.getFastCargos(this.fastCargoExcluir.id_departamento);
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Cargo excluído com sucesso",
        });
        this.hideModal("modalExcluirCargo");
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Matriz e cursos
    async getCursosDisponiveis() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_plataforma?id_usuario=0" +
            "&id_plataforma=" +
            this.retornaSessao(settings.fastSessaoPlataforma).id_plataforma +
            "&id_curso=",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          this.cursosTotal = obj;
          this.cursosFiltro = obj;
        } else {
          this.cursosTotal = [];
          this.cursosFiltro = [];
        }
        console.log(json);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalCursosDisponiveis(cargo) {
      this.fastMatrizNova.id_cargo = cargo.id_cargo;
      this.fastMatrizNova.id_departamento = cargo.id_departamento;
      this.fastMatrizNova.id_plataforma = this.$route.params.id_plataforma;
      this.showModal("modalCursosDisponiveis");
    },
    filtraCurso(e) {
      let text = e.target.value;
      this.cursosFiltro = this.cursosTotal.filter(
        (e) => e.nome_curso.toLowerCase().indexOf(text.toLowerCase()) != "-1"
      );
    },
    async criaMatriz(id_curso) {
      this.fastMatrizNova.id_curso = id_curso;
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma_matriz/insere",
          this.fastAjaxOptions("POST", JSON.stringify(this.fastMatrizNova))
        );

        let json = await resp.json();
        let obj = Array.from(json);
        this.getFastCargos(this.fastMatrizNova.id_departamento);
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Curso inserido com sucesso",
        });
        this.hideModal("modalCursosDisponiveis");
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getCursosCargo(id_cargo) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_matriz/lista_detalhes_cargo?id_cargo=" +
            id_cargo,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastDepartamento.forEach((e, index) => {
            if (e.cargos.length) {
              e.cargos.forEach((c, index2) => {
                if (c.id_cargo == id_cargo)
                  this.fastDepartamento[index].cargos[index2].cursos = obj;
              });
            }
          });
        } else {
          this.fastDepartamento.forEach((e, index) => {
            if (e.cargos.length) {
              e.cargos.forEach((c, index2) => {
                if (c.id_cargo == id_cargo)
                  this.fastDepartamento[index].cargos[index2].cursos = [];
              });
            }
          });
        }
        console.log(this.fastDepartamento);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async removeMatriz(id_departamento, id_cargo, id_curso) {
      let fast_plataforma_matriz = {
        id_plataforma: this.$route.params.id_plataforma,
        id_departamento: id_departamento,
        id_cargo: id_cargo,
        id_curso: id_curso,
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma_matriz/exclui",
          this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_matriz))
        );
        let json = await resp.json();
        let obj = Array.from(json);
        this.getFastCargos(id_departamento);
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Curso excluído com sucesso",
        });
      } catch (e) {
        console.log("Erro", e);
      }
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
/* Tabs */
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 1em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
